
.question_main{
  flex: 1;
}

.question_item{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 5px;
  @include standard-shadow;
  padding: 20px 10px;
  margin-bottom: 10px;
  transition: all 0.1s ease-in;

  &:hover {
    @include hover-shadow;
  }

  &.published{
    &:hover{
      @include standard-shadow;
    }
  }


}


.question_item-text {
  width: 100%;
  padding-right: 20px;
  font-size : 16px;
  margin-left: 15px;
  & .question_item-title{
    color: $admeet-dark-blue;
    font-size: 20px;
    font-weight: 300;
  }
  & .question_item-answer {
    width: 100%;
    font-size: 16px;
    font-weight: 200;
    color: $middle-grey;
  }
}

.question_item-reference {
  font-size: 24px;
  margin: 0 1em;
  color: $admeet-bright-blue;
}

.question_item-link {
  margin: 0 1em;
  i{
    color: $admeet-bright-blue;
  }
}


.question_item-answer-title {
  color: #404040;
  margin-top: 1em;
}

.answered{
  box-shadow: none;
  border: 1px solid $admeet-green;

  .question_item-title {
    color: $admeet-green;
  }

  .question_item-answer {
    color: $admeet-dark-blue;
  }

  & .question_item-reference {
    color: $secondary-color;
  }

  & .question_item-link{
    i{
      color: $secondary-color;
    }
  }

  &:hover{
    border-color: $admeet-white;
    @include standard-shadow;
  }
}


.question_explanation {
  position: relative;
  border: 2px solid $admeet-green;
  border-radius: 5px;
  padding: 0 2rem 2rem;
  margin-bottom: 1rem;
  color: $admeet-green;
  background-color: rgba($admeet-green, .05);

  .arrow {
    box-shadow: 4px -4px 0 0 $admeet-green inset;
  }

  .arrow_title{
    color: $admeet-green;
  }
}

.question_wrapper{
  padding: 0;
  color: $admeet-green;

  h3 {
    padding-top: 1em;
    color: inherit;
  }
}

.query_text{
  padding: 1em 0;

  li {
    margin-left: 3rem;

    &:before{
      content : "•";
      margin-right: 0.5em;
      font-size: 20px;
      // color: $admeet-yellow;
    }
  }

  p {
    margin-top: 0.5em;
  }

  a{
    color: $info-message;
    text-decoration: underline;
  }
}

.question_explanation.cover{
  height: 75px;
  overflow: hidden;
}

.examples {
  // margin:  0 1em 1em 1em;
  text-align: right;
}

.cover .arrow_up{
  transform: rotate(-45deg);
  top: 10px;
  right: 10px;
}

.explanation_title{
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 24px 0;
  width: 100%;
  left: 0;
  top: 0px;
  border-bottom: 2px solid $admeet-green;

  button{
    span{
      position: absolute;
    }
  }
}
