.onboarding_message {
  padding: 1em;
  background-color: rgba(0, 193, 255, 0.3);
  border-radius: 0.5em;
  color: #1b5b83;
  width: 100%;
  margin: 1em 0;
}

.user_register_form,
.onboarding_form,
.onboarding_form_data {

  display: flex;
  width: 100%;

  .address_form {
    justify-content: space-between;
  }

  .text_input {
    margin-right: 1em;
    margin-bottom: 2em;
  }

  input {
    margin-bottom: 0;
  }

  input[name="name"] {
    width: 400px;
  }

  input[name="firstname"] {
    width: 400px;
  }

  input[name="street"] {
    width: 400px;
  }

  input[name="zip_code"] {
    width: 150px;
  }

  input[name="city"],
  input[name="country"] {
    width: 250px;
  }

  .dropdown {
    margin-top: 2em;
    border-color: $main-color;
    border-radius: 0.5em;

    .dropdown_header {
      .dropdown_label {
        color: $policy-title;
        font-size: 16px;
        top: -15%;
      }
    }
  }

  .button-language {
    padding: 3px 9px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 0.5px solid #828282 !important;
    color: #828282 !important;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    background: rgba(130, 130, 130, 0.06);
    margin-right: 10px;
  }

  .active-language button {
    background-color: #0028E6 !important;
    color: #fff !important;
    border: 0.5px solid #0028E6 !important;
  }

  button {
    // margin-top: 2em;
    // padding: 2em;
  }

  .radio-options {
    margin-left: 0;
  }

  .radio_input {
    label {
      min-height: 50px;
      border-radius: 0.5em;
      border-width: 2px;
    }

    input[type="radio"]:checked+label {
      border-width: 2px;
    }
  }
}