.new-card-header {
    text-align: center;
    padding: 10px 0px;
    margin: 0px auto;
}

.new-main-wrapper {
    /* width: 450px; */
    /* box-shadow: 0px 0px 10px rgb(146, 146, 146); */
    border-radius: 20px;

}

/* .new-main-wrapper .new-card-header {
    background-color: #fcb901;
    color: #fff;
} */
.backgroud-card {
    background-color: #fcb901;
    min-height: 200px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.new-card-header p {
    color: #000;
}


.new-card-header h4 {
    margin-top: 0;
}

.logo-card {
    max-height: 30px;
    margin-right: 0;
    margin-left: 5px;
    margin-top: 20px;
    transform: translateY(-27%);
    padding-right: 0;
    align-self: flex-start;
    color: #000;
}

.card-button {
    background-color: #011230;
    padding: 15px;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    width: 100%;
    margin: 20px 0;
}

.new-card-body {
    margin: 10px;
}

.d-flex-card {
    display: flex;
}

.d-flex-card .set-width-exp {
    width: 50%;
}

.m-20-card {
    margin-bottom: 20px;
}

.cardNumberClass {
    border: 2px solid #d3d3d3;
    padding: 15px 4px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.cardExpClass {
    border: 2px solid #d3d3d3;
    padding: 15px 4px;
    border-bottom-left-radius: 8px;
    border-top: 0;
}

.cardCvcClass {
    border: 2px solid #d3d3d3;
    padding: 15px 4px;
    border-bottom-right-radius: 8px;
    border-top: 0;
    border-left: 0;
}

.error-card-message {
    color: red
}

.isprimary-card {
    display: flex;
    align-items: center;
}

.pricetext {
    font-size: 14px !important;
}


/* .payment-method-right .App {
    border-radius: 50% !important;
    background: red !important;
} */



.package-box-inner-wrapp-section {
    border: 1px solid #ccc;
    padding: 0 15px;
    display: flex;
    border-radius: 22px;
    flex-wrap: wrap;
}







.package-inner-sec-main {
    width: 100% !important;
    max-width: 100% !important;
    display: flex !important;
    flex: 0 0 100% !important;
}

.card-payment-section-inner-wrapper {
    width: 25%;
}

.card-payment-section-inner-wrapper-new {
    width: 35%;
}

.left-dark-sec {
    /* width: 60%;
    max-width: 60%;
    flex: 0 0 60%; */
}

.left-dark-sec table.unstriped.table-new-user {
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 15px;
    overflow: hidden;
}

.left-dark-sec td:first-child,
.left-dark-sec th:first-child {
    text-align: left !important;

}

.left-dark-sec td:nth-child(2),
.left-dark-sec th:nth-child(2) {
    text-align: center !important;


}

.left-dark-sec .table-new-user td,
.left-dark-sec .table-new-user th {
    border: none !important;
    text-align: center;
    padding: 13px !important;
}

.addborder {
    border-radius: 15px;
}

.margin-sector {
    margin-bottom: 20px;
}