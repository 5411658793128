.dashboard_versions {
  display: flex;

  .published_version {
    margin-right: 64px;
  }
}

.versions_page{
  color: $admeet-bright-blue;
  margin-top: 64px;

  .version_card{
    margin-bottom: 64px;
  }
}

.version_card-list{
  display: flex;
  flex-wrap: wrap;
}

.version_card{
  position: relative;
  padding-left: 2em;
  margin-right: 64px;
  color: $admeet-dark-blue;

  &:after{
    position: absolute;
    content: '';
    height: 100%;
    width: 6px;
    background-color: $admeet-green;
    top: 0;
    left: 0;
    border-radius: 0.5em;
  }

  &:last-child{
    margin-right: 0;
  }
}

.card_element{
  display: flex;
  align-items: center;
  margin-top: 1em;
  i{
    font-size: 20px;
  }
}

.element_icon{
  margin-right: 1em;
}



.archived_version{
  .version_card{
    &:after{
      background-color: rgba($admeet-bright-blue, .5) ;
    }
  }
}

.see-more{
  align-self: flex-end;
}


