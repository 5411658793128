.integration_process{

  h1, h2 {
    margin: 1em 0;
  }

  .read-only {
    width: 100%;
  }

  .style_cp_snippet {
    margin-bottom: 10px;
  }
}

.process_explanation{
  p, li{
    margin-top: 12px;
  }


  h1, h2 {
    margin: 2em 0 1em;
  }

  li{
    position: relative;
    margin-left: 12px;

    &:before{
      content: "-";
      margin-right: 12px;
      left: 0;
    }
  }
  span{
    padding: 6px 12px;
    margin: 0 6px;
    border: 1px solid $light-grey;
    border-radius: 0.3em;
    box-shadow: inset 0px 1px 2px 1px $light-grey;
  }
}

.link_wrapper {
  margin-top: 10px;
  align-items: center;
  
  .language {
    flex: 1 0 100px; 
  }
  
  #iframe, #iframecookie {
   flex: 5 3 75%;
  }

    
}
