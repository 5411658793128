.modal-overlay-div {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background-color: rgba($admeet-dark-blue, 0.5);
}

.modal-content-div {
  position: fixed;
  margin: 0 20%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  text-align: center;
}

.modal-content-div:after {
  vertical-align: middle;
  display: inline-block;
  height: 100%;
  content: '';
}

.modal-dialog-div {
  position: relative;
  background-color: white;
  text-align: left;
  border-radius: 10px;
  width: auto;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  cursor: default;
  // box-shadow: 0px 5px 15px 1px rgba(173,173,173,1) ;
  @include hover-shadow;

  .query_text {
    padding: 2rem 3rem;
  }
}

.modal_bar {
  padding: 1rem 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $admeet-bright-blue;

  border-top-left-radius: 8px 8px;
  border-top-right-radius: 8px 8px;
}

.modal_title {
  margin: 0;
  color: white;
  font-weight: 300;
}

.query_text {
  padding: 1rem 3rem 1rem;
}

.modal_content {
  padding: 3em;
  text-align: justify;
  max-height: 400px;
  max-width: 600px;
  overflow-y: auto;

  h5 {
    text-align: center;
  }
}

.modal_content_details {
  padding: 3em;
  text-align: justify;
  max-height: 700px;
  max-width: 920px;
  overflow-y: auto;

  h5 {
    text-align: center;
  }
}

.modal_text {
  color: $policy-title;
}

.modal_text ul {
  padding-bottom: 1em;
  padding-left: 1rem;
}

.modal_text li {
  padding-top: 0.5em;
  display: list-item;
  list-style-type: disc;
}

.modal_statement {
  margin-bottom: 2em;
  color: $dark-grey;
  font-size: 20px;

  span {
    color: $error-message;
  }
}

.activation-button-font {
  font-size: 10px;
}

.modal_buttons {
  margin-top: 2em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

// Publish Modal

.categories_status {
  padding-bottom: 1em;
}

.category_list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;

  .category_name {
    font-size: 20px;
    font-weight: 200;
    color: $admeet-bright-blue;
  }

  .category_icon {
    font-size: 20px;
  }

  .complete {
    color: $published;
  }

  .uncomplete {
    color: $error-message;
  }
}

.add_user_container {
  display: flex;
  align-items: center;

  i {
    color: $admeet-green;
  }
}