.privacy-policies-list {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  margin-top: 32px;
  margin-bottom: 72px;
}

@media all and (min-width: 680px) {
  .privacy-policies-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}


@media all and (min-width: 960px) {
  .privacy-policies-list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media all and (min-width: 1180px) {
  .privacy-policies-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}


.privacy-policies {

  h2 {
    margin-top: 72px;
  }

  .policy {
    height: 255px;
    @include standard-shadow;
    border-radius: .5rem;
    padding: 2em;
    overflow: hidden;
    position: relative;
    transition: all 300ms ease-in-out;

    &:hover {
      @include hover-shadow;
    }

    .policy_content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
    }

    .status {
      border-radius: 3px;
      margin-top: 4px;
      padding: 0.4em 0.8em;
      color: white;
    }

    .published {
      background-color: $admeet-green;
    }

    .draft {
      background-color: $draft;
    }

    .blocked {
      background-color: $dark-gray;
    }

    .title {
      color: $admeet-dark-blue;
      font-weight: 800;
      font-size: 1.2rem;
      line-height: 1em;
      margin-bottom: 18px;

      &-wrapper {
        width: 100%;

      }
    }

    & .policy_card-version {
      margin-right: 1.5em;

      & .text {
        display: block;
        font-size: 12px;
        color: $admeet-dark-blue;
        margin-bottom: -8px;
      }

      & .number {
        font-size: 24px;
        color: $admeet-bright-blue;
        font-weight: 800;
      }
    }


    .policy-desc {
      color: $admeet-dark-blue;
      font-size: 14px;
    }
  }
}





.add-policy {
  height: 255px;
  padding: 1em;
  overflow: hidden;
  position: relative;
  border: 3px dashed $admeet-yellow;
  border-radius: .5rem;
  transition: all 0.2s ease-in-out;
  color: $admeet-yellow;
  font-weight: 600;

  .add-policy-container {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      margin-bottom: 1em;
    }

    span {
      font-size: 18px;
    }
  }

  &:hover {
    border: none;
    @include hover-shadow;
    color: $admeet-dark-blue;
  }
}



.add-policy.blocked {
  border-color: $light-grey;
  color: $light-grey;

  p {
    color: $middle-grey;
  }

  &:hover {
    border: 2px dashed $light-grey;
    box-shadow: none;
  }
}

.pagination_wrapper {
  width: 80%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  display: flex;

  a {
    border: 1px solid lightgray;
    border-right: none;

    &.active {
      background-color: lightgrey;
    }

    &:last-child {
      border-right: 1px solid lightgray;
    }
  }
}