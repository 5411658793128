.test_password {
  min-height: 100vh;
  width: 100%;
  background: $admeet-yellow;
  padding: 6% 8%;

  .auth_content {
    height: 4.5rem;
    width: 100vw;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;

    .logo {
      height: 80%;

      img {
        height: 80%;
        width: auto;
        ;
      }
    }

    .dropdown {
      margin: 0;
    }
  }

  .auth_main_content {
    background: $admeet-white;
    border-radius: 7px;
    @include hover-shadow;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 1rem auto;
    padding: 3rem;
    width: 400px;
    position: fixed;
    top: 45vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    z-index: 100;

    .content_titles {
      h1 {
        font-weight: 800;
        position: relative;
        margin-top: 0;
        font-size: 1.7rem;

        &:after {
          position: absolute;
          content: "";
          height: 1px;
          left: 0;
          right: 0;
          bottom: -8px;
          margin: 0 auto;
          background-color: $blue-grey;
          opacity: 0.5;
          width: 50%;
        }
      }

      h2 {
        margin-bottom: 16px;
        font-weight: 200;
      }
    }
  }

  .footer__policies {
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 3rem;
    align-items: center;
    padding: 0 1.5rem;

    ul {
      display: flex;
      justify-content: center;
      color: $admeet-dark-blue;

      li {
        a {
          color: $admeet-dark-blue;
        }

        text-align: center;
        @include transition-color(100ms);

        &:not(:last-of-type) {
          margin-right: 1.5rem;
        }

        &:hover {
          color: $admeet-bright-blue;
        }
      }
    }
  }
}

.auth_form {
  width: 100%;
  margin-top: 12px;

  .text_input {
    color: $admeet-bright-blue !important;

    & input[type='text'],
    input[type='password'] {
      border-color: $admeet-bright-blue;

      +label {
        color: $policy-title;
        font-size: 16px;
      }
    }

  }

  button {
    margin-top: 12px;
    background-color: $admeet-bright-blue;
  }

  .tooltip__box {
    right: -110%;
    top: 0;
  }
}

.warning_message-container {
  padding: 1em;
  background-color: #FEEFB3;
  color: #9f7000;
  width: 100%;
  margin-top: 2em;
}