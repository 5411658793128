.license-table-row {
    font-size: 14px;
    border-bottom: solid 1px #0a0a0a;
    padding-top: 15px;
    padding-bottom: 15px;
    word-break: break-word;
}

.license-table-row a {
    color: #2D98DA;
    font-size: 14px !important;
    word-break: break-word;
}

.license-table-header {
    font-weight: bold;
    position: sticky;
    top: 100px;
    background: #FFF;
    z-index: 1000;
}

.flex-items {
    display: flex;
    align-items: center;
}

.flex-items-col {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.invoice-circle {
    background-color: #0000ff;
    color: #FFF;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

[data-title]:after {
    content: attr(data-title);
    background-color: rgb(36 19 127 / 90%);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    padding: 1px 5px 2px 5px;
    bottom: -1.6em;
    left: 100%;
    white-space: nowrap;
    opacity: 0;
    border: 1px solid #111111;
    z-index: 99999;
    visibility: hidden;
}

[data-title] {
    position: relative;
}