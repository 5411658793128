.tooltip__container{
  position: absolute;
  top: 0;
  right : 0;
  z-index: 100;
}

.tooltip__icon{
  position: absolute;
  cursor: help;
  padding: 0px 8px;
  font-size: 14px;
  border: 2px solid $policy-title;
  color: $policy-title;
  border-radius: 2em;
  width: 25px;
  height: 25px;
  align-self: flex-end;

   + .tooltip__box{
      display: none;
      opacity: 0;
      left: 50px;
      top: -10px;
      width: 500px;
      font-size: 12px;
      transition: all 0.2s ease-in-out;
      box-shadow: 2px 2px 2px rgba(0,0,0,0);
  }

  &:hover{
    + .tooltip__box{
      display: block;
      opacity: 1;
    }
  }
}

.tooltip__container_inline{
  position: relative;
  z-index: 100;
  display: inline-flex;
  vertical-align: bottom;
  margin-left: 10px;
}

.tooltip__box{
  position: absolute;
  width: 100%;
  background: white;
  text-align: left;
  padding: 1em 2em;
  border: 2px solid $policy-title;
  border-radius: 0.4em;

  &:after{
    content: "";
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 10px;
    box-shadow: 2px -2px 0 0 $policy-title inset;
    background: white;
    border-radius: 3px;
    transform: rotate(45deg);
    top: 0;
    left: -21px;
  }

  ul{
    margin-left: 12px;
  }
}
