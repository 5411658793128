.pp__edit{
  display: flex;

  .pp__edit__content{
    width: 50%;
    margin: 1em;
  }
}

.edit__module{
  position: relative;
  margin-bottom: 2em;
  box-shadow: 0px 2px 4px 0px rgba(80,80,80,0.5);
  border-radius: 0.5em;
  padding: 0.5em 2em 2em;
}

.reset_button{
  position: absolute;
  top: 12px;
  right: 12px;
}

.field--locales, .field--countries{
  input{
    height: 40px;
    margin: 0;
    border: none;
    box-shadow: none;
  }
  input:focus{
    box-shadow: none;
  }

  .locale__control{
    border-radius: 0.5em;
    border: 1px solid $light-grey;
  }
  .locale__value-container{
    padding-left: 1em;
  }
}

.field--title{
  input{
    border: 1px solid $light-grey;
    padding-left: 1em;
  }
}


.logo__field--content{
  display: flex;
  align-items: center;
}

.logo--upload{
  margin: 0 auto;
}
.logo--placeholder{
  box-shadow: inset 0 1px 3px rgba(10, 10, 10, 0.1) ;
  border: 1px solid $light-grey;
  border-radius: 0.5em;
  width: 200px;
  height: 200px;
  margin-right: 32px;
}


.color__picker{
  display: flex;
  align-items: center;
}
