.circle_icon{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5em;
  margin-right: 20px;
  width: 50px;
  height: 50px;
  background-color: $admeet-yellow;

  i{
    font-size: 20px;
    color: white;
  }
}

.btn_icon {
  cursor: pointer;
}

.font_8 {
  font-size: 8px;
}

.font_12 {
  font-size: 12px;
}

.font_16 {
  font-size: 16px;
}

.font_20 {
  font-size: 20px;
}

