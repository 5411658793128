
.wizard__form{
  display: flex;
  justify-content: flex-end;
  margin: 1em 0;
}

.wizard__item{
  position: relative;
  margin-right: 50px;

  &:last-child{
    margin-right: 0;
  }

  &:before{
    position: absolute;
    content: '';
    width: 65px;
    top: 18px;
    right: 42px;
    border-bottom: 2px solid $draft;
  }
}


.wizard__completion{
  position: relative;
  margin: 0 auto;
  border-radius: 5em;
  width: 35px;
  height: 35px;
  background-color: $draft;
  transition: all 0.1s ease-in-out;

  i{
    position:absolute;
    font-size: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }

  &:after{
    position: absolute;
    content: "";

    left: 50%;
    transform: translateX(-50%);
    bottom: -12px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    border-bottom: 8px solid $draft;
  }

  &:hover {
    width: 45px;
    height: 45px;
    margin-top: -5px;
    z-index: 100;
  }
}

.wizard__item:first-child{
    &:before{
      content: none;
    }
}

.complete{
  .wizard__completion{
    background-color: $secondary-color;
    &:after {
      border-bottom: 8px solid $secondary-color;
    }
  }
}

.wizard__icon{
  margin-top: 18px;
  width: 50px;
}

