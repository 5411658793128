

.categories{
  margin: 32px 0;
}

.section__heading{
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1{
    margin-top: 0;
  }
}

.icon-part {
  width: 80px;
  height: 80px;
  background: $admeet-dark-blue;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
}

.title-part {
  p {
    font-size: 14px;
    color: $admeet-dark-blue;
  }
}

.pp-edit-text{
  font-size: 12px;
  color: $admeet-bright-blue;
}

.pp-edit-text.complet{
  color: $admeet-green;
}


.policy_category-cards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.policy_category-card{
  flex-basis: 33.33333%;
}

.category {
    margin-bottom: 5em;
    h1 {
      color: $admeet-dark-blue;
    }
    h2 {
      color: $admeet-dark-blue;
      font-weight: 800;
      // text-transform: uppercase;
      position: relative;

      // &:after {
      //   content: '';
      //   display: inline-block;
      //   position: absolute;
      //   width: 100%;
      //   height: .7rem;
      //   left: 0;
      //   bottom: .1em;
      //   background: $admeet-yellow;
      //   z-index: -1;
      //   opacity: .8;
      // }
    }
    h3 {
      color: $admeet-dark-blue;
      text-decoration: none;
    }
}

.arrow_title{
  i {
    color: $admeet-dark-blue;
  }
}

.list_buttons {
  margin-top: 3em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  button{
    margin-top: 0 !important;
    margin-right: 20px;
    &:last-child{
      margin-right: 0;
    }
  }
}

.maintitle {
  margin: 1.5em 0;
  font-size: 32px;
  font-size: 2rem;
  font-weight: 400;
}

.policy_title{
  margin-top: 0;
}




.modif_button {
  background: none;
}

.modif_button i {
  font-size: 2em;
}

.pen-icon {
  margin-top: 8px;
  min-width: 28px;
  width: 28px;
}
