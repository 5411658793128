.account_nav_container{
  margin-top: 24px;
}

.account_nav{
  display: flex;
  justify-content: space-around;
  padding-bottom: 12px;
  border-bottom: 1px solid $medium-gray;
}

.account_nav_item{
  cursor: pointer;
  &.active{
    color: $admeet-bright-blue;
    font-weight: 400;
    border-bottom: 3px solid $admeet-bright-blue;
  }
}

  .column .columns {
  min-width: 50px;
  i {
  color: $admeet-bright-blue;
  }
}

