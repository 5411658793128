.text-form_url {
    position: relative;

    .input_url {
        padding: 2em 0 1.8em 2em;
        border: 1px solid #adadad;
        border-radius: 0.5em;
        box-shadow: none;
        min-height: 80px;
        color: #777777 !important;

    }

    .input_error_url {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0.2em 0 0.2em 32px;
        border-bottom: 2px solid #ff3246;
        border-left: 2px solid #ff3246;
        border-right: 2px solid #ff3246;
        border-bottom-left-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        background-color: #ffd1d6;
        font-size: 12px;
        text-align: left;
        color: #ff3246 !important;
        z-index: 1;
    }

}