.flag__list {
  display: flex;
  border: 1px solid $light-grey;
  border-radius: 0.5em;
  padding: 0.4em 1em;

  box-shadow: inset 0 1px 3px rgba(10, 10, 10, 0.1) ;
}


.flag{
  margin-right: 12px;

  width: 46px;
  height: 36px;
  border-radius: 0.3em;
}

.flag:last-child{
  margin-right: 0;
}

.flag--selected{
  border: 2px solid $policy-title;
};

.flag--unselected{
  border: 2px solid transparent;
};
