.user_message_box {
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #fff;
  padding: 20px 10px;
  transition: all 0.1s ease-in;
  color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0px;
  background: rgba($admeet-dark-blue, .8);
  z-index: 100;

  p {
    margin-bottom: 10px;
    text-align: center;
    word-break: break-word;
  }
}