.sign-in-form {
  margin-top: 5em;

  h1 {
    color: $dark-grey;
    font-size: 2em;
    margin-top: -1em;
    margin-bottom: 1em;
  }
}

ul>li {
  list-style-type: none;
}

.input_example {
  font-size: 14px;
  font-weight: 200;
  color: $light-grey;
}


.validation_error {
  color: $error-message;
  padding-left: 40px;
  position: relative;
  text-align: left;
  margin-top: 12px;

  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -12px;
    width: 24px;
    height: 24px;
    content: '!';
    background: white;
    display: inline-block;
    border: 2px solid $error-message;
    border-radius: 50%;
    text-align: center;
  }
}

label {
  line-height: inherit;
}

// **************************************
// Checkbox Question Form
// **************************************

input[type="checkbox"] {
  display: none;
}


.question_form {

  .checkbox {
    margin-top: 1em;
  }

  .checkbox_options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1.5em;

    .checkbox_input {
      position: relative;
      margin-bottom: 10px;
      width: 100%;
    }

    .input_example {
      padding-left: 0.5em;
      padding-bottom: 1rem;
      margin-top: -14px;
      width: 75%;
      margin-left: 15px;
    }
  }



  input[type="checkbox"]+.checkbox_info {

    border: 1px solid $light-grey;
    border-radius: 0.3em;

    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    height: 100%;
    min-height: 100px;
    margin-bottom: 0;

    .checkbox_label {
      position: relative;
      padding: .5rem calc(10% + 20px) .5em 1em;
      display: block;
      color: $middle-grey;
    }

    .slider {
      position: absolute;
      right: 5%;
      top: 50%;
      margin-top: -10px;
      margin-right: 20px;
      width: 40px;
      height: 20px;
      content: '';
      background: $light-grey;
      border-radius: 1em;
    }

    .slider:after {
      position: absolute;
      top: 50%;
      margin-top: -9px;
      width: 18px;
      height: 18px;
      content: '';
      background: $middle-grey;
      border-radius: 1em;
    }
  }

  input[type="checkbox"]+.checkbox_info_highlighted {
    border: 3px solid $light-grey;
  }

  input[type="checkbox"]:checked+.checkbox_info_highlighted {
    border: 3px solid $admeet-bright-blue !important;
  }

  .create_form {
    .checkbox_label {
      padding: .5rem 0 .5em 1em !important;
    }
  }

  input[type="checkbox"]:checked+.checkbox_info {
    border: 1px solid $admeet-bright-blue;

    .checkbox_label {
      color: $admeet-bright-blue;
    }

    .slider {
      background: $policy-title;
    }

    .slider:after {
      background: $admeet-bright-blue;
      right: 0;
    }
  }
}


// **************************************
// Checkbox Auth Form
// **************************************

.auth_form {

  a {
    font-size: 12px;
    color: $policy-title;
    text-decoration: underline;
  }

  input[type="checkbox"]+.checkbox_label {
    position: relative;
    text-align: left;
    padding-left: 40px;
    color: $policy-title;
    font-size: 12px;
    margin-bottom: 12px;

    &:before {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -12px;
      width: 24px;
      height: 24px;
      content: '';
      background: white;
      display: inline-block;
      vertical-align: middle;
      border: 2px solid $admeet-bright-blue;
      border-radius: 15%;
    }
  }

  input[type="checkbox"]+.error {
    &:before {
      border: 2px solid $error-message;
    }
  }

  input[type="checkbox"]:checked+.checkbox_label {
    &:before {
      background-color: $policy-title;
      box-shadow: inset 0 0 0 4pt white;
    }
  }

}

.auth_form-util {
  display: flex;
  justify-content: space-between;
  margin-top: -12px;
}





// **************************************
// Radio
// **************************************

input[type="radio"] {
  display: none;
}


input[type="radio"]+label {
  position: relative;
  padding: 0.5em 50px .5em 1em;
  margin-bottom: 0.5em;
  display: block;
  border: 1px solid $light-grey;
  border-radius: 0.3em;
  color: $light-grey;
}


input[type="radio"]:checked+label {
  border: 1px solid $admeet-bright-blue;
  color: $admeet-bright-blue;
}


input[type="radio"]+label:after {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  content: '';
  background: white;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid $light-grey;
  border-radius: 50%;

}

.left_radio {

  // Custom radio
  input[type="radio"]+label {
    border: none;
    min-height: 50px;
    padding: 0 0 0 52px;

    &:after {
      left: 0;
    }
  }
}

input[type="radio"]:checked+label:after {
  background-color: $admeet-bright-blue;
  box-shadow: inset 0 0 0 4pt white;
  border: 1px solid $admeet-bright-blue;
}

input[type="radio"][disabled]:checked+label {
  color: $dark-grey;
}

input[type="radio"][disabled]:checked+label:after {
  background-color: $middle-grey;
  box-shadow: inset 0 0 0 4pt white;
  border: 1px solid $dark-grey;
}


.question_form {
  margin: 2em 0;

  label {
    color: $dark-grey;
    font-size: 20px;
  }

  .text_input {
    width: 60%;
  }
}

.text_input {
  margin-bottom: 1.5em;

  &:last-child {
    margin-bottom: 0;
  }
}

.period_checkbox {
  .radio_input {
    label {
      white-space: nowrap;
    }
  }
}


.radio-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 12px;

  & .radio_input {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
      & {
        width: 50%;
      }
    }

    & label {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      height: 100%;
      min-height: 100px;
      margin-bottom: 0;
      margin-left: 0;
    }
  }
}

.more_options {
  margin-left: 12px;
  color: $admeet-bright-blue;
  text-decoration: underline;
}

// **************************************
// Text Input
// **************************************

input[type='text'],
input[type='password'] {
  border-radius: 0.5em;
  height: 50px;
  appearance: none;
}

input[type='text'],
.quill-editor {
  user-select: text;
  /* supported by Chrome and Opera */
  -webkit-user-select: text;
  /* Safari */
  -khtml-user-select: text;
  /* Konqueror HTML */
  -moz-user-select: text;
  /* Firefox */
  -ms-user-select: text;
  /* Internet Explorer/Edge */
}

input[type='text']:read-only {
  background-color: white;
  border-color: $light-gray !important;
  color: $dark-grey !important;

  +label {
    color: $dark-grey !important;
  }
}


.text-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .text_input {
    position: relative;

    .text__label {
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      left: 25px;

      &:hover {
        z-index: 1000;
      }
    }

    & label {
      background: white;
      padding: 0 0.5em;
      color: $dark-grey;
      font-size: 20px;

      display: flex;
      align-items: flex-end;
    }

    & input[type='text'],
    input[type='password'],
    input[type='number'] {
      padding: 2em 0 1.8em 2em;
      border: 1px solid $light-grey;
      border-radius: 0.5em;
      box-shadow: none;
      min-height: 80px;
      color: $middle-grey !important;

    }

    & input[type='text']:focus,
    input[type='password']:focus {
      border-color: $admeet-bright-blue;
      color: $dark-grey !important;
    }

    & input[type='text']:focus+.text__label,
    input[type='password']+label {
      color: $admeet-bright-blue;
    }

    & input[type='text']:read-only:focus {
      border: 1.5px solid $light-grey !important;
      color: $middle-grey !important;
    }

    & .input_example {
      position: absolute;
      bottom: 15px;
      left: 25px;
      margin-top: 22px;
      padding: 0 0.5em;
    }

    &.small {
      & input[type='text'] {
        padding: 0.5em 0 0.4em 0.5em;
        min-height: 20px;
      }
    }
  }

  & .outside-label {
    margin-top: 48px;

    & label {
      transform: translate(-24px, -60%);
      background: transparent;
    }
  }

  & .error {

    & input[type="text"],
    input[type="password"] {
      border-color: $error-message !important;
      display: inline-block;
      padding: 2em 0 2.3em 2em;
    }

    & input[type="text"]:focus,
    input[type="password"]:focus {
      border-color: $error-message;
      border-bottom: 2px solid $error-message !important;
      padding: 2em 0 1.8em 2em;
    }

    & input[type="text"]+.text__label,
    input[type="password"]+.text__label {
      color: #ffa7ac !important;
    }

    & input[type="text"]:focus+.text__label,
    input[type="password"]:focus+.text__label {
      color: #ffa7ac !important;
    }

    & input[type="text"]+.text__label+.input_error,
    input[type="password"]+.text__label+.input_error {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0.2em 0 0.2em 32px;
      border-bottom: 2px solid #ff3246;
      border-left: 2px solid #ff3246;
      border-right: 2px solid #ff3246;
      border-bottom-left-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
      background-color: #ffd1d6;
      font-size: 12px;
      text-align: left;
      color: $error-message !important;
      z-index: 1;
    }

    & input[type="text"]:focus+.text__label+.input_error,
    input[type="password"]:focus+.text__label+.input_error {
      display: none;
    }
  }

  .d-flex-same {
    display: flex;
  }

  .flex-div {
    flex: 1 1 0px
  }
}

.input_warning {
  border: 1px solid $error-message;
  padding: 1em 2em 0.5em 2em;
  border-radius: 0.2em;
  margin-top: -2.5em;
  width: 60%;
  border-top: none;
  color: white;
  background: $error-message;
}

.error_message {
  color: $error-message;
  position: absolute;
  bottom: -12px;
  right: 0;
  font-size: 14px;
  margin-left: 12px;
}

.prefix_input {
  position: relative;

  & input {
    margin-left: 172px;
    width: 50%;
  }

  & .prefix {
    position: absolute;
    bottom: 8px;
  }
}



// **************************************
// Text Area
// **************************************


.text-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;

  & .text_area {
    position: relative;

    & label {
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      left: 25px;
      background: white;
      padding: 0 0.5em;
      color: $dark-grey;
      font-size: 20px;
    }

    & textarea {
      width: 100%;
      height: 250px;
      padding: 2em 2em 1.8em 2em;
      border: 2px solid $light-grey;
      border-radius: 0.5em;
      box-shadow: none;
      color: $middle-grey !important;


      user-select: text;
      /* supported by Chrome and Opera */
      -webkit-user-select: text;
      /* Safari */
      -khtml-user-select: text;
      /* Konqueror HTML */
      -moz-user-select: text;
      /* Firefox */
      -ms-user-select: text;
      /* Internet Explorer/Edge */
    }

    & textarea:focus {
      border: 2px solid $admeet-bright-blue;
      color: $dark-grey !important;
    }

    & textarea:focus+label {
      color: $admeet-bright-blue;
    }
  }
}


.form_locales__list {
  position: absolute;
  z-index: 10;
  right: 4px;
  top: 4px;
  display: flex;
  justify-content: flex-end;
}

.form_locales__item {
  cursor: pointer;
  text-align: center;
  padding: 0.2em 0.5em;
  margin-left: 6px;
  border: 1px solid $admeet-bright-blue;
  border-radius: 0.3em;
  color: $dark-grey;

  &.active {
    border-color: $admeet-bright-blue;
    background-color: $admeet-bright-blue;
    color: $admeet-white;
  }
}

.form_locales__horizontal_item {
  cursor: pointer !important;
  text-align: center !important;
  padding: 0.5em !important;
  margin-left: 6px !important;
  border: 1px solid $admeet-bright-blue !important;
  border-radius: 0.3em !important;
  color: $dark-grey;
  text-transform: uppercase !important;
  font-size: 1rem;

  &.active {
    border-color: $admeet-bright-blue !important;
    background-color: $admeet-bright-blue !important;
    color: $admeet-white !important;
  }
}

// **************************************
// Dropdown
// **************************************

.dropdown {

  border: 1.5px solid $light-grey;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;

  .dropdown_header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding: 1em 1em 1em 2em;
    padding: 1em 4em 1em 2em;

    color: $middle-grey;
    font-size: 1em;
    transition: color 0.3s ease-in-out;

    border: 1px solid $light-grey;
    border-radius: 0.5em;
    min-height: 80px;
    background-color: white;

    .dropdown_label {
      position: absolute;
      top: -20%;
      left: 1em;
      padding: 0 0.5em;
      background-color: white;
      color: $dark-grey;
      font-size: 20px;
      // font-weight: 600;
    }

    &:after {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      right: 24px;
      top: 45%;
      margin-top: -5px;
      transform: rotate(135deg);
      border-right: 3px solid $admeet-white;
      border-top: 3px solid $admeet-white;
      transition: transform 0.3s ease-in-out;
    }
  }

  .dropdown_items {
    padding: 1em 1em 1em 1em;
    position: absolute;
    z-index: 10000;
    background-color: white;
    width: 100%;
    border-bottom-right-radius: 0.3em;
    border-bottom-left-radius: 0.3em;
    border: 2px solid $light-grey;
    border-top: none;
    min-width: 140px;

    .dropdown_item {
      position: relative;
      padding: 0.2em 0 0.2em 2em;
      cursor: pointer;
      font-size: 1em;
      color: $light-grey;

      &:before {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        margin: auto 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 1em;
        border: 1px solid $admeet-bright-blue;
      }

      &:hover {
        color: $dark-grey;

        &:before {
          background-color: $policy-title;
        }
      }

      &.selected {
        color: $dark-grey;

        &:before {
          background-color: $policy-title;
        }
      }
    }

    &:after {
      transition: transform 0.3s ease-in-out;
    }

    &.is-open:after {
      transform: rotate(-45deg); // This will change the arrow direction when the dropdown is open
    }
  }

  &.small {
    .dropdown_header {
      padding: 9px 4em 9px 2em;
      min-height: 0;
      border-color: $admeet-bright-blue;
      border-width: 1px;
      border-radius: 0.3em;
    }

    .dropdown_items {
      min-width: 0;
      border-color: $admeet-bright-blue;
      border-width: 1px;
    }
  }
}

.dropdown {
  &.closed {
    border: none;

    .dropdown_items {
      display: none;
    }
  }

  &.opened {
    border-color: $admeet-bright-blue;

    .dropdown_header {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: 0.5px solid $middle-grey;
      color: $dark-grey;

      .dropdown_label {
        color: $admeet-bright-blue;
      }

      &:after {
        transform: rotate(-45deg) translateY(5px) translateX(-2px);
      }
    }
  }

  &.readOnly {
    .dropdown_header {
      border-color: $lighter-grey;
    }
  }
}

.language_selection {
  .dropdown {
    margin-right: 2em;
    margin-bottom: 0;
    position: relative;
    border-radius: 0;
    border: none;

    .dropdown_header {
      border: 1px solid $admeet-white;
      border-radius: 9999px;
      min-height: 0px;
      background-color: rgba($admeet-white, 0);
      color: $admeet-white;
      min-width: 140px;
      line-height: 1.5em;
    }

    &.closed {
      border: none;
    }

    &.opened {
      .dropdown_header {
        // border-bottom-right-radius: 0;
        // border-bottom-left-radius: 0;
        // border-bottom: 0.5px solid $middle-grey;
      }
    }

    .dropdown_items {
      position: absolute;
      z-index: 10;
      background-color: white;
      width: 100%;
      border-radius: .5rem;
      border: 1px solid $admeet-bright-blue;
      border-top: none;
      min-width: 140px;
      margin-top: .5rem;
    }
  }
}


// **************************************
// Image picker
// **************************************

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}


.inputfile+label {
  color: $admeet-bright-blue;
  padding: 0.5em 1em;
  background-color: white;
  border-radius: 0.5em;
  border: 1px solid $admeet-bright-blue;
  display: inline-block;
  cursor: pointer;

}

.inputfile:focus+label,
.inputfile+label:hover {
  background-color: $admeet-bright-blue;
  color: white;
}


// Select for countries

.country__select {
  position: relative;

  span {
    position: absolute;
    top: -14px;
    left: 24px;
    background: white;
    padding: 0 0.5em;
    color: $dark-grey;
    font-size: 20px;

    &:after {
      content: '*';
      color: red;
    }
  }
}

.countries__input {
  min-height: 70px;
}

.countries__control {
  width: 60%;
  border-color: $light-grey !important;
  border-width: 1.5px !important;
  border-radius: 0.5em !important;

  input {
    height: 50px;
    margin: 0;
    border: none;
    box-shadow: none;
  }
}

.countries__control--is-focused {
  border-color: $admeet-bright-blue !important;
  box-shadow: none !important;
}

.countries__control--is-disabled {
  border-color: $lighter-grey !important;
  background-color: white !important;
}

.countries__value-container {
  padding-left: 2em !important;
}

.countries__menu {
  top: 65px !important;
}


// **************************************
// Cookie Form
// **************************************

.cookies__list {
  display: flex;
  flex-flow: wrap;
}

.cookies__list--nav {
  flex: 1 0px;

  .nav__item {
    cursor: pointer;
    padding: 12px;
    background-color: #efefef;
    color: $admeet-bright-blue;
    border-bottom-left-radius: 0.3em;
    border-top-left-radius: 0.3em;

    &.active {
      background-color: $admeet-bright-blue;
      color: white;
      box-shadow: none;
    }
  }
}

.cookies__list--content {
  flex: 6 0px;
  padding: 24px;

  height: 800px;
  overflow: scroll;

  border: 2px solid $admeet-bright-blue;
  border-radius: 0.3em;
  border-top-left-radius: 0;
}

.cookie-form {
  position: relative;
  padding: 1.5rem;
  background-color: white;
  border-radius: 0.3em;
  margin-bottom: 48px;

  &:after {
    position: absolute;
    content: " ";
    width: 80px;
    height: 10px;
    background-color: $admeet-bright-blue;
    border-radius: 1em;
    bottom: -29px;
    left: calc(50% - 40px);

  }

  &:last-child {
    &:after {
      background-color: white;
    }
  }

  textarea {
    height: 150px !important;
  }

  button {
    align-self: flex-end;
  }

  .dropdown_header {
    padding: 1em 2em;
  }
}

.cookie-form__list_buttons {
  flex-grow: 6;
  margin-top: 3em;
  display: flex;
  justify-content: flex-end;
}

.cookie-form__break {
  flex-basis: 100%;
}

.cookie-form__block {
  display: flex;
}

.cookie-form__elem {
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }
}

.form--75 {
  flex: 9 0px;
}

.form--50 {
  flex: 6 0px;
}

.form--33 {
  flex: 4 0px;
}

.form--66 {
  flex: 8 0px;
}

.form--25 {
  flex: 3 0px;
}

// **************************************
// Quill form
// **************************************

.ql-editor {
  padding-right: 56px !important;
  min-height: 150px;
}

.quill__label {
  margin-bottom: 0.3rem;
  position: relative;
  padding: 0 0.5em;
}

.system_cookies_results {
  margin-top: 5px;
  width: 300px;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  z-index: 1000;
}

.system_cookies_results::-webkit-scrollbar {
  display: none;
}

.system_cookies_results .system-cookie-result {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}

.system-cookie-result p {
  margin-left: 10px;
}

.system-cookie-result:hover {
  background-color: lightgrey;
}

.table-new-user {
  margin: 20px;

  td,
  th {
    // border: 1px solid $middle-grey;
    border: 1px solid #F2F2F2;
    text-align: center;
    width: 15%
  }

  td:nth-child(1) {
    background-color: #d8d8d8;
    text-align: left;
    color: #0A0A0A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
  }

  th:nth-child(1) {
    text-align: left;
  }

  th {
    background-color: #d8d8d8;
  }
}

.table-new-user-name {
  margin: 20px;

  td,
  th {
    border: 1px solid $middle-grey;
    text-align: center;
  }

  td:nth-child(1) {
    background-color: #d8d8d8;
    text-align: left;
    font-weight: bold;
  }

  th:nth-child(1) {
    text-align: left;
  }

  th {
    background-color: #d8d8d8;
  }
}

.inline-checkbox {
  display: block !important;
  margin-right: 10px !important;
  margin-top: 5px !important;
}

.a-tag {
  a {
    text-decoration: underline;
    color: blue;
    margin-left: 5px;
    cursor: pointer;
  }
}

.mb-10 {
  margin-bottom: 20px;
}

.package-box {
  .card {
    padding: 30px 22px;
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
    justify-content: flex-start;
    width: 278px;
    height: 370px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1.5px solid #F2F2F2;
    background: #FFF;

    h4 {
      margin: 0;
      word-break: break-word;
      color: #333;
      text-align: center;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }

    span {
      margin: 28px 0 120px;
      display: block;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      color: #333;
      font-weight: 400;
      min-height: 80px;
    }

    p {
      color: #333;
      margin-top: auto;
      word-break: break-word;
      color: #333;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &.active {
      cursor: pointer;
      border: 4px solid $admeet-green;
      border-radius: 16px;
      border: 1.5px solid #0028E6;
      background: rgba(0, 40, 230, 0.05);
      // background-color: #ffeebe;

      // h4 {
      //   color: $admeet-green
      // }

      // span {
      //   color: $admeet-green
      // }

      // p {
      //   color: $admeet-green
      // }

    }
  }
}

.card.back-ground-blue {
  h4 {
    font-size: 25px;
    color: #D3D3D3;
    margin: 0;
    word-break: break-word;
    text-transform: capitalize;
    font-weight: 900;
  }

  span {
    margin: 20px 0;
    font-size: 18px;
    color: #D3D3D3;
    display: block;
  }

  p {
    font-size: 20px;
    color: #D3D3D3;
    font-weight: bold;
    line-height: 1.2;
    margin-top: auto;
    word-break: break-word;
  }
}

.button-buy-extra {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.icon-color {
  color: $admeet-bright-blue;
}

.set-tooltip {
  .display-icon-div {
    position: absolute;
    left: 0
  }
}

.set-tooltip [data-title]:hover:after {
  width: 500px;
  text-wrap: wrap;
  text-align: start;
}

.button-class button {
  background-color: transparent !important;
}

.icon-color {
  color: $admeet-bright-blue;
}

.margin-top-package {
  margin-top: 20px;
}


.break-word-set {
  word-break: break-word;
}

.active-language button {
  background-color: #0028E6 !important;
  color: #fff !important;
}






.vat-section {
  width: 50%;
}

.address_form {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start !important;
}

.address_form .text_input {
  width: 48%;
}

.address_form input[type="text"] {
  width: 100% !important;
}

.address_form .flex-div.newmargin {
  margin-right: 15px;
}

.address_form .text_input:nth-child(2) {
  margin-right: 0 !important;
  width: 49.5%;
}

.account-wrapper .text_input {
  width: 49%;
}

.account-wrapper .text_input input[type="text"] {
  width: 100%;
  // border: 1px solid #f2f2f2 !important;
}

.account-wrapper .text_input input[type="text"]:nth-child(2) {
  width: 50%;
}

.text_input input[type="text"] {
  // border: 1px solid #f2f2f2 !important;
}

.address_form .dropdown_header {
  // border: 1px solid #f2f2f2 !important;
}

.company_informations .dropdown_header {
  border: 1px solid #f2f2f2;
}

.employee_rate .dropdown_header {
  border: 1px solid #f2f2f2;
}

.payment-label {
  min-width: 55px;
  display: inline-block;
  text-align: left;
  font-weight: 600;
}

.sc-bYMpWt svg {
  display: none !important;
}


.high-contrast {
  .checkbox-wrapper {
    display: flex;
  }

  .high-contrast-content {
    display: flex;
    align-items: center;
  }

  .checkbox-wrapper span {
    margin-left: 10px;
    font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 18px;
  }

  input:checked+label {
    background: #0028E6;
  }


  .features-set label {
    cursor: pointer;
    text-indent: -9999px;
    width: 52px;
    height: 22px;
    background: #c2c2c2;
    display: block;
    border-radius: 100px;
    position: relative;
    z-index: 1;
  }

  .features-set label:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  .features-set input:checked+label:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }
}