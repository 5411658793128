.breadcrumbs {
  display: flex;
  flex-direction: row;

  margin: 1em;
}


.breadcrumbs_item {
  color: $admeet-dark-blue;
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin: .1rem .5rem 0;
    }

    & p{
      text-decoration: underline;
      max-width: 150px;
      white-space: nowrap;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;

    }

    & span {
      visibility: hidden;
      position: fixed;
    }
  }
}

.breadcrumbs_item-txt:hover span {
  visibility: visible;
}


.breadcrumbs_item:last-child{

    & p {
      text-decoration: none;
      cursor: default;
    }
}
