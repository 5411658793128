header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  height: 100px;
  align-items: center;
}

.shadow-wrapper {
  box-shadow: 0px 0px 1px 0px grey;
  background-color: $admeet-bright-blue;
}

.main-header-wrapper {
  width: 100%;
  padding: 12px 0 12px 0;
}

.main-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;

    .logo {
      max-height: 35px;
      margin-right: 0;
      margin-left: 5px;
      transform: translateY(-27%);
      padding-right: 0;
      align-self: flex-start;
      // border-right: 0.05em solid white;
      // margin-bottom: .7em;
    }
    .no-border{
      border-right: 0;
    }
    .nav-link{
      margin-left: 1em;
    }
    a {
        color: white;
    }
    i {
        margin-right: 0.5em;
    }
    .is-active{
      font-weight: bold;
      text-decoration: underline;
      padding:20px 10px;
    }
    .menu-right {
      display: flex;
      align-items: center;
      .profil_btn {
        margin-right: .5rem;
        img{
          margin-right: 0.3em;
          width: 30px;
        }
      }
      .visible-false {
        display: none;
      }
      .visible-true {
        display: block;
        position: absolute;
      }

      .button_logout{
        width: 25px;
        max-width: initial;
      }
    }
}

@media only screen and (max-width: 600px) {
  .menu-left {
    text-align: center;
  }
  .main-header {
    display: block;
    .logo {
      max-height: 60px;
      margin: 0 0 10px 0;
      padding: 0;
      border-right: none;
    }
    .nav-link{
      padding: 10px;
      margin-left: 0;
      margin-bottom: 10px;
      display: block;
      text-align: left;
    }
    .is-active{
      padding: 10px;
    }
  }
}

