@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

body {
  font-family: 'Open Sans', sans-serif !important;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
  display: block;
  margin: 0;
}

.annotation{
  position: absolute;
  color: #6e6e6e;
  font-style: italic;
  font-size: 14px;
  top: 100%;
}

.fa-arrow-left{
  font-size: 32px;
}

.text-center {
  text-align: center;
}

.no-margin{
  margin: 0;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-50 {
  margin-right: 50px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-bottom-10{
  margin-bottom: 0px;
}

.margin-bottom-20{
  margin-bottom: 20px;
}

.margin-bottom-40{
  margin-bottom: 40px;
}

.padding-25 {
  padding: 25px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-20 {
  padding-right: 20px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.padding-left-5 {
  padding-left: 5px;
}

.padding-left-10 {
  padding-left: 10px;
}

.round {
  border-radius: 1.5em;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.button-radius-25 {
  border-radius: 0.25em;
}

/*
*
* Float
*
 */

.right {
  float: right;
}

.left {
  float: left;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.fixed {
  position: fixed;
}

.width-50{
  width: 50%;
}

.width-75{
  width: 75%;
}

.width-100{
  width: 100%;
}

.validation{
  display: block;
  color: red;
  margin-bottom: 1em;
}
