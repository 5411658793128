// **************************************
// Duration Form
// **************************************


.duration_form{
  display: flex;
}


.criteria_select{
  margin-right: 2em;
  flex: 6;

  .text_area{
    width: 100%;
    textarea{
      height: 150px;
    }
  }
}


.duration_select{
  flex: 1;

  .dropdown_header{
    align-items: center;
    font-size: 16px;
  }

  .dropdown_items{
    padding: 1em 2em 1em 2em;
  }

  input{
    width: 120px;
    height: 80px;
  }

  label{
    left: 6px !important;
  }
}

.period_checkbox{
  display: flex;
  margin-top: 1em;
  flex: 1;

  input[type="radio"] + label{
    border: none;
  }
}
