.modal-content {
  background-color: #fefefe;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  border: 1px solid #888;
  min-width: 400px;
  max-height: 400px;
  overflow-y: auto;
}
