.main-footer-wrapper {
  width: 100%;
  height: 75px;
  background-color: $main-footer;
  display: flex;
  align-items: center;
  .main-footer{
    background-color: transparent;
    padding: 0.25em;
    display: flex;
    justify-content: space-between;
    color: $light-grey;

    .footer__policies{
     ul{
       display: flex;

       li {
         margin-right: 12px;

         &:first-child:after{
           content: '-';
           margin-left: 12px ;
         }
       }

     }
    }
  }
}
