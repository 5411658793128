html,
body {
  width: 100%;
  height: 100%;
  margin: 0;

  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

html {
  scroll-behavior: smooth;
}

#app {
  height: 100%;
}

main {
  min-height: calc(100% - 150px);
  padding-bottom: 32px;
}

.error-message {
  span {
    color: $error-message !important;
  }
}

.error_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;

  button {
    margin-top: 32px;
  }
}

iframe {
  width: 100%;
  height: 100%;
}

.main_content {
  flex: 1;
  padding: 100px 0;
}

button {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: unset;
}


// TITLE

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $admeet-dark-blue;
  margin-top: 1em;
  margin-bottom: 0;
}

h1 {
  font-weight: 800;
  font-size: 32px;
  font-size: 2rem;
  font-weight: 800;
}

h2 {
  font-weight: 600;
  font-size: 24px;
  font-size: 1.5rem;
  color: $admeet-dark-blue;
}

h3 {
  font-weight: 600;
  margin-top: 0;
  font-size: 20px;
  font-size: 1.25rem;
  color: $admeet-dark-blue;
}

h4 {
  font-weight: 800;
  font-size: 24px;
  font-size: 1.50rem;
  color: $admeet-dark-blue;
  margin-bottom: 1em;
}

h5 {
  font-weight: 400;
  font-size: 20px;
  font-size: 1.25em;
  margin-bottom: 1em;
  color: $dark-grey;
}

h6 {
  margin: 0;
  font-size: 14px;
  font-size: 0.85em;
  font-weight: 300;
  color: $admeet-bright-blue;
}

input {
  margin: 0 !important;
  color: $dark-grey !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  background-color: white;
  transition: background-color 5000s ease-in-out 0s;
}

.important_text {
  color: #404040;
  font-size: 20px;
}

// Error, Warning, Info

.error_color {
  color: $error-message !important;
}

.warning_message {
  color: $warning-message !important;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  min-height: 75px;
  padding: 1rem 2rem;
  border: 2px solid $warning-message;
  border-radius: 5px;
  background: rgba($warning-message, .05);

  p {
    flex: 1;
  }

  span {
    flex: 10;
  }
}

.legislation_info {
  color: $admeet-green !important;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  min-height: 75px;
  padding: 1rem 2rem;
  border: 2px solid $admeet-green;
  border-radius: 5px;
  background: rgba($admeet-green, .05);

  p {
    flex: 1;
  }

  span {
    flex: 10;
  }
}


.info_message {
  color: $info-message !important;
}

p {
  margin-bottom: 0;
}

// ARROW

.arrow {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 10px;
  box-shadow: 4px -4px 0 0 $policy-title inset;
}

.arrow_up {
  transform: rotate(135deg);
  top: 20px;
  right: 10px;
}

.arrow_down {
  transform: rotate(-45deg);
  top: 10px;
  right: 10px;
}

.arrow_left {
  transform: rotate(45deg);
  top: 5px;
  right: 10px;
}

.arrow_right {
  transform: rotate(-135deg);
  top: 5px;
  right: 10px;
}

.arrow_title {
  display: flex;
  margin-top: 0.5em;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;

  h2 {
    margin: 0;
  }
}

.hidden {
  display: none !important;
}

//CROSS

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 21px;
  height: 21px;
  border: 1px solid white;
  border-radius: 20px;
  opacity: 0.8;
}

.close:hover {
  opacity: 1;
}

.close:before,
.close:after {
  position: absolute;
  left: 9px;
  top: 2px;
  content: ' ';
  height: 16px;
  width: 2px;
  background-color: white;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.parent-table {
  display: table;
}

.center-child-table {
  display: table-cell;
  vertical-align: middle;
}

// buttons

#app {
  .is-pill {
    border-radius: 99999px;
    line-height: 1.5em;
    padding: .7em 1.7em;
  }
}

.btn {
  &_pill {
    border-radius: 99999px;
    line-height: 1.5em;
    padding: .7em 1.7em;
    transition: all 100ms ease-in-out;
    border: 1px solid $admeet-black;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 1em;
    }

    &.is-white {
      background-color: $admeet-white;
      color: $admeet-dark-blue;
      border-color: $admeet-white;

      &:hover {
        background-color: $admeet-bright-blue;
        color: $admeet-white;
      }
    }

    &.is-black {
      background-color: $admeet-black;
      color: $admeet-white;

      &:hover {
        background-color: $admeet-bright-blue;
        outline: 1px solid $admeet-white;
      }
    }

    &.is-blue {
      background-color: $admeet-bright-blue;
      color: $admeet-white;
      border-color: $admeet-bright-blue;

      &:hover {
        background-color: $admeet-white;
        color: $admeet-bright-blue;
        border-color: transparent;
        @include hover-shadow;
      }
    }

    &.is-error-sub {
      background-color: $error-message;
      color: $admeet-white;
      border-color: $error-message;

      &:hover {
        background-color: $admeet-white;
        color: $error-message;
        border-color: transparent;
        @include hover-shadow;
      }
    }

    &.is-yellow {
      color: $admeet-white;
      background-color: $admeet-yellow;
      border-color: $admeet-yellow;

      &:hover {
        background-color: $admeet-white;
        border-color: $admeet-white;
        color: $admeet-dark-blue;
        @include hover-shadow;
      }
    }

    &.is-orange {
      background-color: $warning-message;
      color: $admeet-white;
      border-color: $warning-message;

      &:hover {
        background-color: $admeet-white;
        color: $warning-message;
        border-color: transparent;
        @include hover-shadow;
      }
    }

    &.is-light-blue {
      background-color: $info-message;
      color: $admeet-white;
      border-color: $info-message;

      &:hover {
        background-color: $admeet-white;
        color: $info-message;
        border-color: transparent;
        @include hover-shadow;
      }
    }

    &.is-green {
      color: $admeet-white;
      background-color: $admeet-green;
      border-color: $admeet-green;

      &:hover {
        background-color: $admeet-white;
        border-color: $admeet-white;
        color: $admeet-green;
        @include hover-shadow;
      }
    }

    &.is-outlined {
      background: transparent;

      &.is-white {
        color: $admeet-white;
      }

      &.is-blue {
        color: $admeet-bright-blue;
        // border: 1px solid $admeet-bright-blue;
      }

      &.is-green {
        color: $admeet-green;
        border-color: $admeet-green;
      }

      &:hover {
        background: $admeet-white;
        border-color: $admeet-white;
        color: $admeet-bright-blue;
        @include hover-shadow;
      }
    }

    &.is-borderless {
      border-color: transparent;

      &:hover {
        @include standard-shadow;
      }
    }
  }
}

.has-text-blue {
  color: $admeet-bright-blue;
}

// Helpers

.is-flex {
  display: flex;
}

.is-jsb {
  justify-content: space-between;
}

.is-aic {
  align-items: center;
}

.mr-05 {
  margin-right: 0.5rem;
}

.mr-1 {
  margin-right: 1rem;
}

// Loading spinner

.loading-spinner {
  position: relative;
  margin: 10rem auto;
  display: inline-block;
  color: $admeet-bright-blue;
  transform: translate(-50%, -50%);

  svg {
    position: absolute;
    top: 0;
    left: 0;

    &:first-of-type {
      animation: spin 500ms ease-in-out infinite;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// scanning animation

.scanning-animation {
  width: 80%;
  height: 300px;
  background: url('../../../assets/img/website-scanner-icon.png') center top no-repeat;
  background-size: contain;
  margin: 0 auto;
  position: relative;
  margin-bottom: 3.5rem;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    border-radius: 10em;
    background-color: $admeet-yellow;
    opacity: .2;
    animation: scan 5s ease-in-out alternate infinite;
  }

  @keyframes scan {
    from {
      top: 0;
    }

    to {
      top: 90%;
    }
  }
}