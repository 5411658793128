.flex_right{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.flex_cl{
  display: flex;
  flex-direction: column;
}

.flex_row{
  display: flex;
  flex-direction: row;
}

.flex_split{
  justify-content: space-between;
}

.flex_center{
  align-items: center;
}

.flex_1{
  flex: 1;
}
