.license-table-row {
  font-size: 14px;
  border-bottom: solid 1px #0a0a0a;
  padding-top: 15px;
  padding-bottom: 15px;
  /* word-break: break-all; */
  word-break: break-word;
}

.license-table-row a {
  color: #2D98DA;
  font-size: 14px !important;
  word-break: break-word;
}

.license-table-header {
  font-weight: bold;
  position: sticky;
  top: 100px;
  background: #FFF;
  z-index: 1000;
}

.flex-items {
  display: flex;
  align-items: center;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: rgb(36 19 127 / 90%);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: -1.6em;
  left: 100%;
  white-space: nowrap;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
}

[data-title] {
  position: relative;
}


.published-new {
  position: relative;
  overflow: hidden;
}

.published-new::before {
  content: "Active";
  background: green;
  padding: 5px 0;
  position: absolute;
  transform: rotate(-39deg);
  top: 11px;
  left: -27px;
  border-radius: 5px;
  width: 120px;
  text-align: center;
  color: #fff;
}

.icon-size {
  font-size: 20px;
  cursor: pointer;
}

.color-active {
  color: green;
}

.primary-button {
  display: inline-flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: #46C246 !important;
}

.primary-button span {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.make-primary {
  color: #0D0D0D;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  padding: 5px;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: pointer;
}

.dataTable-card .rdt_TableHeadRow {
  background: #F9FAFB;
  color: rgba(13, 13, 13, 0.60);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dataTable-card .rdt_TableRow:hover {
  background-color: #F9FAFB;
  /* your desired hover color */
}

.dataTable-card .rdt_TableRow {
  height: 80px;
  color: #0D0D0D;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dataTable-card .rdt_TableRow:nth-child(even) {
  background-color: #F9FAFB;
  /* your desired hover color */
}

.rdt_Pagination button {
  background-color: transparent !important;
}

.rdt_Pagination select {
  margin: 0 !important;
}

/* .exportTooltip:after {
  left: -85px !important;
}

.licenseTooltip:after {
  left: -50px !important;
} */