.customisation {

  input[type="text"] {
    border-color: $light-grey !important;
  }

  // Custom Quill

  .ql-toolbar {
    &.ql-snow {
      border: 1px solid $light-grey;
      border-bottom: 1px solid $light-grey;
      border-top-left-radius: 0.5em;
      border-top-right-radius: 0.5em;
    }
  }

  .ql-container {
    &.ql-snow {
      border: 1px solid $light-grey;
      border-top: none;
      border-bottom-left-radius: 0.5em;
      border-bottom-right-radius: 0.5em;

      max-height: 200px;
      overflow: scroll;
    }
  }

  h4 {
    margin-top: 48px;
  }

}


.banner_validation {
  color: $error-message;
  position: absolute;
  bottom: -32px;
}

// HEADER

.customisation__header {
  display: flex;
}

// PREVIEW

.customisation__preview {
  flex: 8 0px;
}

.banner__preview {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
}

.styled__banner {
  position: relative;

  &:before {
    // position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    z-index: 100;
    background-color: transparent;
  }
}

.banner__preview__close-btn {
  position: absolute;
  cursor: pointer;
  top: 24px;
  right: 24px;
  z-index: 1000;
  padding: 4px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.5);

  color: $admeet-bright-blue;

  i {
    padding: 8px 12px;
    font-size: 24px;
    border-radius: 100%;
    border: 2px solid $admeet-bright-blue;
  }

  &:after {
    position: absolute;
    content: 'esc';
    font-size: 12px;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
  }
}

//COLORS



.customisation__visual {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

@media screen and (max-width: 768px) {
  .customisation__visual {
    display: flex;
    flex-direction: column;
  }
}

.customisation__theme {
  flex: 2 0px;

  .radio-options {
    flex-direction: column;
  }
}

.customisation__colors {
  flex: 2 0px;

  .cst__colors {
    display: flex;
    flex-wrap: wrap;
  }

  .cst_default_colors {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cst__colors__item {
    cursor: pointer;
    width: 42px;
    height: 42px;
    padding: 2px;
    border-radius: 0.5em;
    border: 2px solid transparent;
    transition: border 0.2s ease-in-out;

    div {
      height: 100%;
      border-radius: 0.3em;
    }

    &.selected {
      border: 2px solid $policy-title;
    }
  }
}

//POSITION

.customisation__position {
  flex: 3 0px;

  .cst__position__content {
    display: flex;
  }

  .cst__position__item {
    cursor: pointer;
    padding: 4px;
    border-radius: 0.5em;
    border: 2px solid transparent;
    transition: border 0.2s ease-in-out;
    margin-left: 42px;

    img {
      max-width: 128px;
    }

    &.selected {
      border: 4px solid $admeet-bright-blue;
    }
  }
}


//COOKIES

.customisation__cookies {
  .cst__cookies__nav {
    display: flex;
    justify-content: space-between;

    .cst__cookies__nav--item {
      cursor: pointer;
      width: 25%;
      padding: 12px 0;
      text-align: center;
      border-top-left-radius: 0.3em;
      border-top-right-radius: 0.3em;

      &.selected {
        background-color: $admeet-bright-blue;
        color: white;
      }
    }
  }

  .cst__cookies__content {
    display: flex;
    padding: 32px 32px 24px;
    border: 1px solid $light-grey;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;

    .cst__cookies__content--left {
      flex: 7 0px;
      margin-right: 32px;

      input[type="text"] {
        border: 1px solid $light-grey;
      }

      input[type="text"]+label {
        font-size: 16px;
      }

      .ql-toolbar {
        &.ql-snow {
          border: 1px solid $light-grey;
          border-bottom: 1px solid $light-grey;
        }
      }

      .ql-container {
        &.ql-snow {
          border: 1px solid $light-grey;
          border-top: none;
        }
      }
    }

    .cst__cookies__content--right {
      flex: 3 0px;

      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-left: 32px;
      border-left: 1px solid $light-grey;

      .warning_message {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        i {
          margin-right: 12px;
        }
      }
    }
  }
}

.table_container {
  width: 100%;
  font-size: 9.5pt;
  border: 1px solid rgba(188, 188, 188, 0.5);
  margin-top: 12px;
}

.table_container p {
  line-height: 16px;
  margin: 0.5em 0;
}

.table_container .owner {
  flex: 2 0px;
}

.table_container .purpose {
  flex: 4 0px;
}

.table_header {
  display: flex;
  align-items: center;
  font-weight: 400;
  color: #909090;
  padding: 12px;
  text-align: left;
}

.table_row {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 12px;
  border-top: 1px solid rgba(188, 188, 188, 0.5);
}

.table_row .italic {
  font-size: 9pt;
  font-style: italic;
  font-weight: 200;
  color: #838383;
}

.table_row_content,
.table_title {
  padding-right: 24px;
}

.table_row_content:last-child,
.table_title:last-child {
  padding-right: 0;
}