@mixin standard-shadow {
  box-shadow: 0 -1px 0 0 rgba(0,0,0,.1),
              0 2px 5px 0 rgba(51,51,104,.2);
}

@mixin hover-shadow {
  box-shadow: 0 8px 16px rgba(19,41,104,.2);
}

@mixin transition-color($speed) {
  transition: color $speed ease-in-out;
}