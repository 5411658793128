.back-ground-blue {
    background: #001230;

}

.font-color {
    color: #cf9b09 !important;
}

.icon-class {
    margin-right: 5px;
}

.icon-add {
    color: green;
    font-size: 25px;
}

.icon-close {
    color: #404040;
    font-size: 25px;
}

.success-color {
    color: green;
}

.input-group-addon {
    position: absolute;
    padding: 8px 20px;
    font-size: 20px;
    background-color: #eee;
    white-space: nowrap;
    /* display: table-cell; */
    right: 17px;
    top: 1px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    min-height: 78px;
    display: flex;
    align-items: center;
}

.subdomain {
    position: relative;
}

.card-header {
    text-align: center;
    padding: 10px 0px;
    margin: 0px auto;
}

.main-wrapper {
    /* width: 450px; */
    box-shadow: 0px 0px 10px rgb(146, 146, 146);

}

.main-wrapper .card-header {
    background-color: #66bb6a;
    color: #fff;
}

.main-wrapper .email-row div,
.email-row,
.card-detail div,
.card-detail,
.expiry-row div,
.expiry-row,
.remember-me div,
.remember-me,
.card-footer div {
    display: flex;
    align-items: center;
}

.main-wrapper .email-row div,
.card-detail div,
.expiry-row div,
.remember-me div,
.card-footer div {
    justify-content: space-between;
}

.main-wrapper .card-body {
    height: 250px;
}

.main-wrapper .icon {
    color: #66bb6a !important;
    font-size: 14px;
    object-fit: cover;
    width: 20px;
}

.main-wrapper .card-footer {
    background-color: skyblue;
    padding: 20px 0px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.main-wrapper .card-footer div div {
    margin-right: 20px;
    margin-bottom: 13px;
}

.main-wrapper .email-row input,
.card-detail input {
    border: none;
    outline: none;
    padding-left: 20px;
    width: 400px;
}

.main-wrapper .expiry-row div div input {
    width: auto;
    border: none;
    outline: none;
    padding-left: 20px;
}

.main-wrapper .expiry-row div div:first-child {
    border-right: 1px solid rgb(201, 201, 201);
    padding: 10px;
}

.main-wrapper .expiry-row div div:last-child {
    padding-left: 10px;
}

.main-wrapper .expiry-row {
    border-bottom: 1px solid rgb(201, 201, 201);
    margin-left: 10px;
}

.main-wrapper .email-row,
.card-detail,
.remember-me {
    border-bottom: 1px solid rgb(201, 201, 201);
    padding: 10px;
    margin-left: 10px;
}



/* check box css */
.main-wrapper .round {
    position: relative;
}

.main-wrapper .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 23px;
    left: 0;
    position: absolute;
    top: 0;
    width: 23px;
}

.main-wrapper .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 4px;
    transform: rotate(-45deg);
    width: 10px;
}

.main-wrapper p {
    font-size: 10px;
}

/* .main-wrapper .round input[type="checkbox"] {
    visibility: hidden;
}

.main-wrapper .round input[type="checkbox"]:checked+label {
    background-color: #66bb6a;
    border-color: #66bb6a;
}

.main-wrapper .round input[type="checkbox"]:checked+label:after {
    opacity: 1;
} */

.d-flex-align {
    display: flex;
    align-items: center;
}

.d-flex-align i {
    margin-right: 10px;
}

.inpuclass input {
    border: none;
    outline: none;
    box-shadow: none;
}

.inpuclass input:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.border-bottom-set {
    border-bottom: 1px solid #000;
    margin: 0 15px !important;
}

.border-right-set {
    border-right: 1px solid #000;
}

.margin-10 {
    margin-left: 10px;
}

.margin-15-new {
    margin: 0 15px !important;
}

.margin-left-15 {
    margin-left: 15px;
}

/* .question_form input[type="checkbox"]+.checkbox_info .slider {
    position: relative !important;
    right: 5%;
    top: 50%;
    margin-top: -10px;
    margin-right: 20px;
    width: 40px;
    height: 20px;
    content: '';
    background: #adadad;
    border-radius: 1em;
}

.question_form input[type="checkbox"]+.checkbox_info {
    border: none !important;
} */

.onboarding_form .flex-div .dropdown {
    margin-top: 0;
}

.onboarding_form .flex-div .dropdown .dropdown_header .dropdown_label {
    color: #404040;
}

.card-height {
    height: 500px;
}

.newmargin .dropdown {
    margin-top: 0 !important;
}

.flex-div .dropdown_label {
    color: #000 !important;
}

.pricetext {
    font-size: 14px !important;
}





.detail-wrapper-left {
    width: 50%;
    margin-right: 5%;
}

.detail-wrapper-right {
    width: 45%;
}

.detail-wrapper-right .row {
    margin-bottom: 10px;
}

.detail-wrapper-left h3 {
    margin-bottom: 10px;
    /* text-transform: capitalize; */
}

.detail-wrapper-right h3 {
    /* text-transform: capitalize; */
}

.payment-method {
    width: 100%;
}

.payment-method-wrapper {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    justify-content: space-between;
}

.payment-method-wrapper-new {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    justify-content: center;
}

.paymentclass .payment-method-left {
    width: 40%;
}

.payment-method-left-inner {
    width: 100%;
    border: 2px solid #F3F3F3;
    border-radius: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    cursor: pointer;
}

.payment-method-left-inner.selected {
    border: 2px solid #55E1D1;
}

.payment-method-left input[type="radio"] {
    display: block;
    margin-left: 15px !important;
    height: 20px !important;
    width: 20px !important;
}

.payment-method-left .card-detail {
    margin-left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border: none;
}

span.card-number {
    margin-right: 15px;
}

span.card-holder {
    font-weight: 700;
    margin-bottom: 5px;
    display: inline-block;
}



.payment-method-left .checkbox {
    position: relative;
}

.payment-method-left input[type="radio"]:checked,
.payment-method-left input[type="radio"]:not(:checked) {
    position: absolute;
    left: 9px;
    opacity: 0;
    z-index: 9999;
    top: 0px;
}

.payment-method-left input[type="radio"]+label {
    padding: 0;
}

.payment-method-left input[type="radio"]:checked+label,
.payment-method-left input[type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 0;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}

.payment-method-left input[type="radio"]:checked+label:before,
.payment-method-left input[type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 16px;
    top: -10px;
    width: 18px;
    height: 18px;
    border: 1px solid #55E1D1;
    border-radius: 100%;
    background: #fff;
}

.payment-method-left input[type="radio"]:checked+label:after,
.payment-method-left input[type="radio"]:not(:checked)+label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #55E1D1;
    position: absolute;
    top: 9px;
    left: 20px;
    border-radius: 100%;
    transition: all 0.2s ease;
    box-shadow: none !important;
    border: none !important;
}

.payment-method-left input[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.payment-method-left input[type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.payment-method-right {
    width: 48%;
}

.row .arrowset {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.arrowset img {
    width: 35px;
    height: 35px;
    margin-top: 1rem;
}

.paymentselectbutton {
    width: 100%;
    background: #0028e6;
    padding: 20px 18px;
    border-radius: 10px;
    margin-top: 25px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.paymentselectbutton img {
    margin-left: 5px;
}

.border-set-addcard {
    border: 2px dashed #F3F3F3;
    text-align: center;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.plusicon {
    display: flex !important;
    align-items: center !important;
}

.button-end {
    text-align: end;
    width: 100%;
}

.text-align-end-button {
    text-align: end;
}

.table-new-user tbody tr td:first-child,
.table-new-user thead tr th:first-child {
    width: 15%;

}

.marginBottom-50 {
    margin-bottom: 70px;
}

.dropdown_header-payment-mode {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    cursor: pointer !important;
    flex-direction: row !important;
}

.payment-mode-drop-down .dropdown_arrow {
    margin-left: auto;
    /* pushes the arrow to the end of the container */
    padding: 0 8px;
    /* add some spacing around the arrow */
}

.dropdownaddnew {}

.dropdown-wrapper {
    width: 50%;
}

.dd {
    display: flex;
    gap: 10px;
    padding-top: 9px;
}

.page-title {
    color: #001230;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 0;
}

.page-sub-title {
    color: #001240;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.detail-section-title {
    color: #001230;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.field-label {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.field-text {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.billing-address .row {
    margin-bottom: 10px;
}

.account-owner .row {
    margin-bottom: 10px;
}

.account-owner .row:first-child {
    margin-bottom: 0;
}

.subdomain {
    position: relative;
    display: flex;
}

.subdomain span:first-child {
    line-height: 1.5;
    margin-right: 5px;
}

div#packpaymentdetails {
    display: flex;
    justify-content: flex-start;
}

.card-payment-section-inner-wrapper-new {
    width: 100% !important;
}

#packpaymentdetails .row {
    width: 100%;

}

#packpaymentdetails .package-box .card {
    padding: 30px 22px;
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
    justify-content: flex-start;
    width: 380px;
    height: 500px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1.5px solid #F2F2F2;
    background: #FFF;
    border-radius: 22px;
    background: #001230;
}

#packpaymentdetails .package-card-title {
    color: #FFF !important;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
}

#packpaymentdetails .package-name {
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 200px !important;
    display: block;
}

#packpaymentdetails .back-ground-blue p {

    color: #CF9B0B;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 226px;
    margin: 0 auto;
}

#packpaymentdetails table {
    margin: 0 auto;
}

.payment-method-wrapper-new {
    justify-content: flex-start !important;
}

.payment-card-wrapper {
    margin-top: 15px;
}



/********* Payment Success **********/
.payment-success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.success-color p {
    color: #0D0D0D;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.payment-title {
    color: #0D0D0D;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.payment-overview .row {
    margin-bottom: 10px;
}

.order-summary-title {
    color: #0D0D0D;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.order-summary-sub-title {
    color: #828282;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.payment-success-overview-detail .row {
    margin-bottom: 10px;
}

.detail-wrapper-left h3 {
    margin-bottom: 10px;
    color: #0D0D0D;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.payment-success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.icon-subdomain span {
    line-height: 1;
    margin-right: 5px;
    ;
}

.icon-subdomain .field-text {
    display: flex;
    align-items: center;
}

.back-ground-remove {
    background-color: transparent !important;
}

.main_content .checkbox-border input[type=radio]:checked+label {

    color: #0028E6;
    border-radius: 8px;
    border: 1px solid #0028E6;
    background: rgba(0, 40, 230, 0.05);
}