:root{
  --primary-color : $admeet-bright-blue;
  --secondary-color : #6c9fbc;
  --third-color : #e0f8ff;
  --logo : url("../../../assets/img/admeet_logo-quote.svg");
  --filter : none,
}

.preview_container{
  margin-top: 32px;
  font-size: 15px;
}

.preview {
  position: relative;
  height: 1000px;
  text-align: justify;
  overflow: hidden;
  border : 2px solid $admeet-dark-blue;
  border-top: none;
  -webkit-overflow-scrolling: touch;

  iframe{
    width: 100%;
    height: 100%;
    border: none;
  }

  .download_pdf{
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

.preview__header{

  background-color: $admeet-dark-blue;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .dropdown {
    .dropdown_header {
      background-color: transparent;
      border-color: $admeet-white;
      color: $admeet-white;
      min-width: 130px;
    }
  }
}

.preview__policies{
  display: flex;
  justify-content: flex-end;
  &--item{
    cursor: pointer;
    padding: 1em 4em;
    border-top-left-radius: 0.6em;
    border-top-right-radius: 0.6em;
    border: 1px solid $admeet-dark-blue;
    border-bottom: none;
    color: $admeet-dark-blue;
  }
  &--item.active{
    color: white;
    background-color: $admeet-dark-blue;
  }
  &--item.disabled{
    color: $middle-grey;
    border-color: $middle-grey;
  }
}
.preview__link{

  cursor: pointer;
  color: white;
  display: inline-block;
  padding:  1.5em 2em;
  transition: all .2s ease-in-out ;

  &.active{
    background-color: $admeet-bright-blue;
  }
}

.preview__link:hover{
  color: white;
  background-color: $admeet-bright-blue;
}

.online__preview > *{
  flex: 1 100%;
}

.online__preview {
  font-family: 'Roboto', sans-serif;
  color: #4B4B4B;
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;

  @media all and (min-width: 800px) {
    .navbar { flex: 2 0px; }
  }

  @media all and (min-width: 800px) {
    main { flex: 5 0px; }
    .navbar { order: 1; }
    main    { order: 2; }
    footer  { order: 3; }
  }

  header {
    z-index: 1;
    background-color: white;
    box-shadow: 0px 2px 10px 0px rgba(65,65,65,0.5);
    height: 80px;
    padding: 0 32px;

    display: flex;
    flex-direction: row;
    align-items: center;

    .logo {
      flex: 2 0px;
      position: relative;
      height: 50px;
      background-image: var(--logo);
      background-size: contain;
      background-repeat: no-repeat ;

      img{
        display:block;
        max-height: 60px;
      }
    }
  }


  main li::before {
    content: "\25A0";
    color: var(--primary-color);
    font-weight: bolder;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  /*---------------------------------------------------------------------------------
  *
  * Navigation bar
  *
  ---------------------------------------------------------------------------------*/

  .navbar {
    background-color: var(--third-color);
    padding-top: 48px;
    overflow: visible;
    height: 1000px;
    position: sticky;
    top: 0;
  }

  .navbar > ul,li{
    margin: 0;
    padding: 0;
    list-style: none;
  }


  .navbar > ul {
    margin: 1.5em 1em 1em 2em;
  }

  .navbar__item{
    margin-right: 1em;
    font-size: 16px;
    font-weight: 300;
  }


  .navbar__item--link{
    position: relative;
    cursor: pointer;
    padding: 0.5em 1em 0.5em 1em;
    display: block;
    font-size: 14px;
    font-weight: 500;
  }

  .navbar__item--link:after{
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    right: 0;
    top: 50%;
    margin-top: -5px;
    transform: rotate(45deg);
    border-right: 1px solid black;
    border-top: 1px solid black;
    transition: all 0.3s ease-in-out;
  }

  .navbar__item--link.active:after{
    transform: rotate(135deg);
    margin-top: -10px;
    right: -5px;
  }


  .navbar__item--sublist{
    position: relative;
    padding: 0 0 0 20px;
    margin-left: 20px;
    border-left: 1px solid var(--secondary-color);

    transform: translateY(-100%);
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }

  .navbar__item--link.active {
    transform: translateY(0px);
    opacity: 1;
    max-height: 100%;
    border-left: 3px solid var(--primary-color);
  }

  .navbar__item--link.active + .navbar__item--sublist{
    transform: translateY(0px);
    opacity: 1;
    max-height: 100%;
  }

  .sublist__item{
    cursor: pointer;
    padding: 0.5em 0.8em;
    margin: 0;
    color: #838383;
    font-size: 14px;
  }

  .sublist__item.active{
    color: var(--primary-color);
    font-weight: 400;

  }

  @media all and (max-width:800px) {
    .navbar {
      display: none;
    }
  }


  /*----------------------------------------------------------------------------------
  *
  * Main content
  *
  ----------------------------------------------------------------------------------*/

  main{
    position: relative !important;
    padding: 32px 72px 72px;

  }

  @media all and (max-width:800px) {
    main {
      padding: 16px;
    }
  }


  .main_section{
    display: none;
    padding-bottom: 100px;
  }

  .sub_section{

    border-left: 1px solid var(--secondary-color);
    padding: 32px 0 32px 48px ;
    margin-left: 4px;
    min-height: 350px;
  }

  section.visible{
    display: block;
  }

  h1,h2,h3,h4,h5,h6{
    margin: 1em 0 0;
  }

  h1{
    font-size : 40px;
    font-weight: 700;
    letter-spacing: 1px;
    color: var(--primary-color);

  }

  h2{
    font-size : 32px;
    font-weight: 400;
    color: #C7DDEE;
    margin-top: 2em ;
  }

  h3{
    margin-left: -50px;
    margin-top: 0;
    margin-bottom: 32px;
    padding: 10px 34px;
    border-left: 3px solid var(--primary-color);
    font-size: 24px;
    font-weight: 500;
    color: var(--primary-color);
    background-color: var(--third-color);
  }

  h4{
    font-size: 20px;
    font-weight: 400;
    color: #2a2a2a;
    span{
      color: $dark-grey;
      font-weight: 200;
      font-size: 16px;
    }
  }

  h5{
    font-size: 20px;
    font-weight: 300;
    color: #236192;
  }

  @media all and (max-width:800px) {
    .sub_section {
      border: none;
      padding: 18px 0;
    }

    h3{
      margin-left: 0;
    }
  }

  .maintitle_card{
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    margin: 32px 0 ;
    padding: 1em 5em 1em 1em;
    border: 2px solid var(--primary-color);
  }


  .maintitle_card:after{
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    right: 32px;
    top: 50%;
    margin-top: -5px;
    transform: rotate(45deg);
    border-right: 3px solid var(--primary-color);
    border-top: 3px solid var(--primary-color);
    transition: all 0.3s ease-in-out;
  }

  .maintitle_card.active:after{
    transform: rotate(135deg);
    margin-top: -8px;
    right: 27px;
  }

  .maintitle_card img{
    width: 100px;
    height: 100%;
    filter: var(--filter);
  }

  .maintitle_card h2{
    margin: 0 0 0 16px;
    color: #4B4B4B;
    font-size: 20px;
    font-weight: 300;
  }

  address{
    border-left: 6px solid var(--primary-color);
    padding: 8px 32px;
    background-color: var(--third-color);
    margin: 16px 0;

    p{
      margin: 8px;
    }
  }

  .title_div {
    margin-top: 32px;
  }

  .title_div div{
    margin-left: 32px;
    padding: 1em 2em;
    background-color: rgba(199, 221, 238, 0.35);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    transform: translateY(-100%);
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;

  }

  .title_div.active div {
    transform: translateY(0px);
    opacity: 1;
    max-height: 100%;
  }

  .title_div h5{
    cursor: pointer;
    border-radius: 3px;
    border-bottom: 2px solid #236192;
    background-color: white;
    margin-top: 0;
    position: relative;
    padding: 1em 64px 1em 0;
    z-index: 100;
  }

  .title_div h5:after{
    position: absolute;
    content: '';
    width: 14px;
    height: 14px;
    right: 32px;
    top: 50%;
    margin-top: -7px;
    transform: rotate(45deg);
    border-right: 2px solid black;
    border-top: 2px solid black;
    transition: all 0.3s ease-in-out;
  }

  .title_div.active h5:after{
    transform: rotate(135deg);
    margin-top: -10px;
    right: 30px;
  }

  .data_cards {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }

  .data_cards > .card {
    text-align: left;
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
    padding: 0.5em 2em 2em 2em;
    flex: 1;
    margin: 0 2% 2% 0;

  }


  @media all and (max-width:800px) {
    .data_cards {
      grid-template-columns: repeat(1, 100%);
    }
  }


  ul{
    list-style: none;
    margin-bottom: 1em;
    padding-left: 40px;
  }

  li{
    margin-top: 0.5em;
  }


  p {
    margin-top: 1em ;
    line-height: 24px;
  }


  .btn_list{
    margin-top: 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  button{
    cursor: pointer;
    border: none;
    box-shadow: 0px 2px 5px 0px rgba(80,80,80,0.5);
    border-bottom: 2px solid #236192;
    background-color: white;
    position: relative;
    padding: 1.2em 64px 1.2em 64px;

    color: #669FD2;
    font-size: 16px;
    font-weight: 300;

  }

  button:first-child{
    padding-right: 32px;
  }

  button:last-child{
    padding-left: 32px;
  }

  button:after{
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    right: 32px;
    top: 50%;
    margin-top: -6px;
    transform: rotate(45deg);
    border-right: 3px solid #236192;
    border-top: 3px solid #236192;
  }

  button.previous:after{
    left: 32px;
    transform: rotate(225deg);
  }

  footer{
    background-color: #236192;
  }

  /* Table */

  table {
    border-collapse: collapse;
  }

  th{
    align: left;
  }
  .header_table{
    border-bottom: 2px solid #236192;
  }

  td > p {
    border-left: 3px solid #C7DDEE;
  }

  th > p {
    border-left: 3px solid #C7DDEE;
  }

  tr > td:first-child > p{
    border-left: 5px solid #C7DDEE;
  }

  tr:nth-child(2n) {
    background-color: #dbefff;
  }

  tr:nth-child(2n) > td:first-child > p {
    border-left: 5px solid #236192;
  }


  td > p{
    margin: 1em;
    padding: 1em;
  }

  th > p{
    margin: 1em;
    padding: 1em;
  }


  /*@media all and (max-width:800px) {
    table, caption, tbody, tfoot, thead, tr, th, td {
      display: block;
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      font-size: 100%;
      vertical-align: baseline;
      background: transparent;
    }
  }*/


  .row_table{
    display: block;
  }

  .powered_by{
    position: absolute;
    right: 72px;
    bottom: 24px;
    display: flex;
  }

  .powered_by img {
    width: 160px;
  }
}


