.license-table-row {
  font-size: 14px;
  border-bottom: solid 1px #0a0a0a;
  padding-top: 15px;
  padding-bottom: 15px;
  word-break: break-word;
}

.license-table-row a {
  color: #2D98DA;
  font-size: 14px !important;
  word-break: break-word;
}

.license-table-header {
  font-weight: bold;
  position: sticky;
  top: 100px;
  background: #FFF;
  z-index: 1000;
}

.flex-items {
  display: flex;
  align-items: center;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: rgb(36 19 127 / 90%);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: -1.6em;
  left: 0 !important;
  white-space: nowrap;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
}

[data-title] {
  position: relative;
}

.div1-legislation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  /* overflow: hidden; */

  position: relative;
}

.div1-legislatione-image {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.div1-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: #000; */
  top: 0;
  left: 0;
  border-radius: 100%;
  opacity: 0.5;
  border-radius: 100%;
}

.circle {
  background-color: #0000ff;
  color: #FFF;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.yellow-circle {
  background-color: #FFB900;
  color: #0D0D0D;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #0D0D0D;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.domain-name-wrapper {
  display: flex;
  padding: 8px;
  /* justify-content: center; */
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  background: #F8F8F8;
  margin-top: 20px 0;
}

.domain-name-wrapper span {
  color: #0D0D0D;
  /* font-size: 15px; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.license-privacy-type-main {
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: rgba(0, 40, 230, 0.50);
}

.license-privacy-type-main span {
  color: #FFF;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.license-cookie-type-main {
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: rgba(255, 185, 0, 0.50);
}

.license-cookie-type-main span {
  color: #0D0D0D;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.license-valid {
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #F8F8F8;
}

.license-valid p {
  color: #0D0D0D;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.license-traffic {
  display: flex;
  align-items: center;
  gap: 4px;
}

.license-format {
  display: flex;
  align-items: flex-start;
  gap: 6px;
}

.div1-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  bottom: -2px;
  right: 1px;
  border: 2px solid;
  z-index: 1;
}

.div1-dot-green {
  background: #46C246;
}

.div1-dot-red {
  background: #EB001B;
}

.license-banner {
  display: inline-flex;
  align-items: flex-start;
  gap: 6px;
}

.dataTable-license .rdt_TableHeadRow {
  background-color: #F9FAFB;
  color: rgba(13, 13, 13, 0.60);
  /* font-size: 10px; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dataTable-license .rdt_TableRow:hover {
  background-color: #F9FAFB;
  /* your desired hover color */
}

.dataTable-license .rdt_TableRow:nth-child(even) {
  background-color: #F9FAFB;
  /* your desired hover color */
}

.rdt_TableFilter input {
  width: 100%
}

.license-banner span.consent-tooltip::after {
  left: -80px !important;
}