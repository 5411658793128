.rdrDateRangePickerWrapper {
    position: relative;
}

.rdrDefinedRangesWrapper {
    position: absolute;
    top: 68px;
    left: -226px;
    z-index: 1000;
    display: none;
}

.rdrDateDisplayWrapper {
    /*display: none;*/
}

.rdrDateRangeWrapper {
    position: absolute;
    top: 68px;
    z-index: 1000;
    border: 1px solid #adadad;
}

.search-close-button {
    position: absolute;
    top: 380px;
    z-index: 1000;
    border: none;
    background-color: #00548A;
    color: white;
    width: 20px;
    height: 20px;
    font-size: 14px;
}

.search-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 40px 0;
}

.search-input {
    position: relative;
    flex: 1 1;
}

.search-input:not(:last-of-type) {
    margin-right: 10px;
}

.search-form .search-input .search-input-field {
    padding: 1em;
    border: 1px solid #adadad;
    border-radius: 0.5em;
    box-shadow: none;
    min-height: 60px;
    color: #777777 !important;
}

.search-form .search-input label {
    background: white;
    padding: 0 0.5em;
    color: #404040;
    font-size: 20px;
    display: flex;
    align-items: flex-end;
}

.search-input-label {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    left: 25px;
}

.search-button {
    width: 100%;
    color: white;
    min-height: 60px;
    font-size: 20px;
}

.data-table-extensions-filter label {
    display: none !important;
}

.data-table-extensions>.data-table-extensions-filter>.filter-text {
    border: 0;
    border-bottom: 1.5px solid #adadad;
    border-radius: 0;
    outline: none;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    margin-left: 0 !important;

}

.circle-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.text-align-center {
    text-align: center;
}

.align-center-domain {
    display: flex;
    align-items: center;
}

.domain-gap-15 {
    gap: 15px
}

.tabicon {
    align-items: center;
    display: flex;
    gap: 10px;

}