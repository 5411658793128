.show-policy {
  .policy_page{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    padding-bottom: 1em;
    border-bottom: 2px solid $admeet-bright-blue;

    h1{
      margin-bottom: 0;
    }
    .policy_page-info {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-end;

      button{

        display: flex;
        flex-direction: row;
        align-items: center;

        font-size: 14px;
        padding: 0.8em 1.5em;
        margin-left: 16px;

        i{
          font-size: 14px;
          margin-right: 8px;
        }
      }

      .info_text{
        margin-top: -20px;
      }
      span{
        margin-left: 2em;
        color: $admeet-bright-blue;
      }
      strong{
        margin-left: 0.5em;
      }
    }
  }
  h2 {
    margin-bottom: 0.5em;
    color: $admeet-dark-blue;
    font-size: 45px;
  }

  a.publish {
    background-color: $publish;
  }
  i {
    font-size: 0.8em;
    color: $admeet-bright-blue;
  }
  .status {
    color: $admeet-bright-blue;
    font-size: 1em;
    margin-left: -3.75em;
  }
  .button-right{
    margin-right: 2.5em;
  }
}
