.new-card-header {
    text-align: center;
    padding: 10px 0px;
    margin: 0px auto;
}

.new-main-wrapper {
    /* width: 450px; */
    box-shadow: 0px 0px 10px rgb(146, 146, 146);

}

/* .new-main-wrapper .new-card-header {
    background-color: #fcb901;
    color: #fff;
} */
.backgroud-card {
    background-color: #fcb901;
    min-height: 200px;
}

.new-card-header p {
    color: #000;
}


.new-card-header h4 {
    margin-top: 0;
}

.logo-card {
    max-height: 30px;
    margin-right: 0;
    margin-left: 5px;
    margin-top: 20px;
    transform: translateY(-27%);
    padding-right: 0;
    align-self: flex-start;
    color: #000;
}

.card-button {
    background-color: #011230 !important;
    padding: 15px;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    width: 100%;
    margin: 20px 0;
}

.new-card-body {
    margin: 10px;
}

.d-flex-card {
    display: flex;
}

.d-flex-card .set-width-exp {
    width: 50%;
}

.m-20-card {
    margin-bottom: 20px;
}

.cardNumberClass {
    border: 2px solid #d3d3d3;
    padding: 15px 4px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.cardExpClass {
    border: 2px solid #d3d3d3;
    padding: 15px 4px;
    border-bottom-left-radius: 8px;
    border-top: 0;
}

.cardCvcClass {
    border: 2px solid #d3d3d3;
    padding: 15px 4px;
    border-bottom-right-radius: 8px;
    border-top: 0;
    border-left: 0;
}

.error-card-message {
    color: red
}

.min-height-set {
    margin-right: 0 !important;
}

.min-height-set input {
    min-height: 0 !important;
    padding: 5px !important;
}