.progress-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 48px 0;

  .progress-bar__el {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex: 1 0px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 32px;
      height: 32px;
      margin: 8px 0;
      border-radius: 100%;

      i {
        color: white;
      }
    }

    i {
      font-size: 20px;
    }

    &:after {
      position: absolute;
      content: "";
      top: 50%;
      margin-top: -6px;
      left: 65%;
      height: 6px;
      width: 70%;
      border-radius: 5px;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }
}



.progress-bar__el.blocked {
  color: $draft;

  span {
    background-color: $draft;
  }

  i {
    color: $draft;
  }

  &:after {
    background-color: $draft;
  }
}

.progress-bar__el.loading {
  color: $admeet-bright-blue;

  span {
    background-color: $admeet-bright-blue;
  }

  i {
    color: $admeet-bright-blue;
  }

  &:after {
    background-color: $admeet-bright-blue;
  }
}


.progress-bar__el.loading.questions {

  &:after {
    background-color: $admeet-bright-blue;
    height: 10px;
    margin-top: -8px;
  }

  &:before {
    position: absolute;
    content: "";
    top: 50%;
    margin-top: -8px;
    left: 65%;
    height: 10px;
    width: 70%;
    background-color: #E8F2F9;
    border-radius: 5px;
  }
}

.progress-bar__el.done {
  color: $admeet-green;

  span {
    background-color: $admeet-green;
  }

  i {
    color: $admeet-green;
  }

  &:after {
    background-color: $admeet-green;
  }
}

.progress-bar__el.loading.questions:after {
  width: var(--progress-bar_percentage, 0);
}


/*
 New dashboard
 */

.dashboard {

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 32px 0;
  color: $admeet-dark-blue;

  .dashboard_element {
    background-color: white;
    padding: 2em;
    border-radius: 7px;
    border: 1px solid $admeet-light-grey;
    // box-shadow: 0px 1px 3px 0px rgba(80,80,80,0.5);
    flex: 1 100%;

    &.el_left {
      position: relative;
      flex: 1 0 0;
    }

    &.el_right {
      margin-left: 2em;
      flex: 3 0px;
    }

    &.el_bottom {
      margin-top: 2em;
    }

    h1 {
      margin-top: 0;
    }
  }
}

.dashboard_info {

  .dashboard_info-title {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      color: $dark-grey;
    }
  }

  .dashboard_color {
    width: 20px;
    height: 20px;
    border-radius: 0.3em;
  }

}

.dashboard_section {
  margin-top: 32px;
  border-top: 2px solid rgba(101, 126, 146, 0.5);

  input {
    width: 100%;
  }
}

.read-only {
  background-color: white !important;
  border-radius: 0.3em;
  padding: 0.5em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.small_input {
  width: 80px;
  text-align: center;
  margin: 0 6px;
}


.dashboard_dates-title {
  p {
    color: $dark-grey;
  }
}

.user-card {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  border-top: 2px solid rgba(101, 126, 146, 0.5);
  padding: 20px 0;
  align-items: center;

  .deleteicon {
    color: $error-message;
    cursor: pointer;
  }

  .checktrue {
    color: $admeet-green;
  }
}