$main-header: white;
$main-footer: #ededed;

// Color set new identity 2021

$admeet-dark-blue: #001230;
$admeet-yellow: #FFB900;
$admeet-green: #50D3C4;
$admeet-bright-blue: #0028E6;
$admeet-black: #000;
$admeet-white: #FFF;
$admeet-light-grey: #e4e6e9;

// Omio warning yellow rgb(247, 175, 72)
// Omio warning yellow border rgb(208, 126, 9)

// END Color set new identity 2021

$main-color: #00548a;
$font-color: #236192;

$secondary-color: #03C6C4;

$lighter-grey: #e6e6e6;
$light-grey: #adadad;
$middle-grey: #777777;
$dark-grey: #404040;
$blue-grey : #657E92;

$publish: #adadad;

$published: #20bf6b;
$draft: #B3C1CC;

$policy-title: #2D98DA;
$dashboard-color: #E5F4FF;



$error-message: #ff3246;
$warning-message: #f7af48;
$info-message: #008cff;